import React from "react";
import { Link, useHistory } from "react-router-dom";
import { eraseLocalStorage } from "../common/Functions";
import { useAppContext } from "../context/AppContext";

import "../styles/components/Header.scss";

import Language from "./Language.jsx";

/* Assets */
import Logo from "../assets/images/logo.webp";

export default function Header(props) {
  const [{ boot, theme }, dispatch] = useAppContext();
  const { push } = useHistory();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    eraseLocalStorage();
    push("/welcome");
  };

  return (
    <header className="header">
      <h1 className="header__title">{props.title}</h1>
      <Link to="/welcome" className="header__logo-link"
        style={{
          filter: theme === "dark" ? "invert(1)" : "invert(0)",
          backgroundImage: `url(${Logo})`
        }}
      />
      <div className="header__mobile">
        <img className="header__logo" src={Logo} alt="CRM Pilot"
          style={{ filter: "invert(1)" }}
        />
        <div className="header__user">
          <h4 className="header__user-name">{localStorage.getItem("firstName")}</h4>
          <Link to="/welcome" className="header__user-picture">
            <img src={localStorage.getItem("profileImage")} alt={localStorage.getItem("firstName")} className="header__user-picture-content" />
          </Link>
        </div>
      </div>
      <div className="header__actions">
        <Language function={props.function} />
        <button
          type="button"
          onClick={handleLogout}
          className="header__button"
        >
          {boot.login.signOut}
        </button>
      </div>
    </header>
  );
}
